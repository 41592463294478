<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Volunteering" subtitle="Help make the convention a success by volunteering for available roles and slots." :tabs="tabs" />

	<app-content-filters class="filters">

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-checklist v-model="filter.days" text="Day" tooltip="Select days" :options="references.days" />
		<app-content-filters-check v-model="filter.hidefilled" text="Hide Filled" tooltip="Toggle filled slots" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="No slots found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Role" />
				<app-table-column text="Day" />
				<app-table-column text="From" />
				<app-table-column text="To" />
				<app-table-column align="center" text="Volunteers" />
				<app-table-column />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onRoleClick(item.role.id)" :success="item.session.is.volunteering" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.role.name" />
					<app-table-cell-text :text="item.date.start | formatDate('dddd')" />
					<app-table-cell-text :text="item.date.start | formatDate('h:mma')" />
					<app-table-cell-text :text="item.date.end | formatDate('h:mma')" />
					<app-table-cell-text align="center" :text="item.count.filled.toString() + ' / ' + item.capacity.toString()" />

					<app-table-cell-action icon="volunteers.clash" theme="red" v-tooltip="'Clashing slot'" v-on:click="onJoinClick(item.id)" :large="true" v-if="item.session.can.join && item.session.is.clashing" />
					<app-table-cell-action icon="volunteers.join" theme="white" v-tooltip="'Volunteer for slot'" v-on:click="onJoinClick(item.id)" :large="true" v-if="item.session.can.join && !item.session.is.clashing" />
					<app-table-cell-action icon="volunteers.leave" theme="white" v-tooltip="'Remove volunteer slot'" v-on:click="onLeaveClick(item.id)" :large="true" v-if="item.session.can.leave" />

					<app-table-cell-action icon="volunteers.info" theme="white" v-tooltip="'Read more about this role'" v-on:click="onRoleClick(item.role.id)" :large="true" />

				</app-table-row>

			</app-table-body>

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: 'auto 120px 100px 100px 100px 32px 32px',
			references: {
				days: []
			},
			filter: {
				search: '',
				days: [],
				hidefilled: true
			},
			pagination: false,
			endpoint: 'convention/volunteers/slots'
		}

	},

	created: function() {

		if (!this.$canVolunteer) this.$router.push({
			name: 'Convention.Volunteers.Register'
		})

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Volunteers.Roles', text: 'Roles' },
				{ name: 'Convention.Volunteers.Slots', text: 'Slots' },
				{ name: 'Convention.Volunteers.Assignments', text: 'My Assignments', count: this.$store.getters['session/count'].volunteers.total }
			]

		}

	},

	methods: {

		onRoleClick: function(id) {

			this.$router.push({
				name: 'Convention.Volunteers.Role',
				params: {
					id: id
				},
				query: {
					redirect: 'slots'
				}
			})

		},

		onJoinClick: function(id) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignment',
				params: {
					id: id
				},
				query: {
					redirect: 'slots'
				}
			})

		},

		onLeaveClick: function(id) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignment',
				params: {
					id: id
				},
				query: {
					redirect: 'slots'
				}
			})

		}

	}

}

</script>

<style scoped>

</style>